import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Common Components
import Login from '../components/common/auth/login';
import Register from '../components/common/auth/register';
import Profile from '../components/common/auth/Profile'
import SelectPackage  from '../components/selectPackage';
import Stripe from '../components/Stripe';

// Admin Components
import AdminDashboard from '../modules/admin/dashboard';
import { AdminEmails } from '../modules/admin/Inbox';
import { Subscriptions } from '../modules/admin/Subscription';
import { Owners } from '../modules/admin/Owners';
import { Settings } from '../modules/admin/Settings';
import PaymentHistory from '../modules/admin/History';

// Owner Components
import OwnerDashboard from '../modules/owner/dashboard';
import { OwnerEmails } from '../modules/owner/Inbox';
import { Locations } from '../modules/owner/spaces/Locations';
import { Spaces } from '../modules/owner/spaces/Spaces';
import { Providers } from '../modules/owner/Providers';
import { Subscription } from '../modules/owner/Subscriptions';
import { Reservations } from '../modules/owner/Reservations';
import { OwnerSettings } from '../modules/owner/Settings';

// Provider Components
import ProviderDashboard from '../modules/provider/dashboard';
import { ProviderEmails } from '../modules/provider/Inbox';
import { ProviderSpaces } from '../modules/provider/space/Spaces'; // Ensure this matches the export
import { Reservation } from '../modules/provider/Reservations';
import SpaceDetail from '../modules/provider/space/SpaceDetail';
import { ProviderSettings } from '../modules/provider/Settings';

import '../assets/scss/styles.scss';
import '../assets/js/dropdown';
import '../assets/js/main';
import OwnerLayout from '../layouts/ownerlayout';
import AdminLayout from '../layouts/adminlayout';
import ProviderLayout from '../layouts/providerlayout';
import ConfirmEmail from '../components/Confirm';
import ResetPassword from '../components/ResetPassword';
export const AppRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/package" element={<SelectPackage />} />
                <Route path="/stripe" element={<Stripe />} />
                <Route path="/confirmEmail" element={<ConfirmEmail />} />
                <Route path="/resetPassword" element={<ResetPassword />} />

                {/* Admin Routes */}
                <Route path="/admin" element={<AdminLayout />}>
                    <Route index element={<AdminDashboard />} />
                    <Route path="inbox" element={<AdminEmails />} />
                    <Route path="owners" element={<Owners />} />
                    <Route path="subscriptions" element={<Subscriptions />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="history" element={<PaymentHistory />} />
                </Route>

                {/* Owner Routes */}
                <Route path="/owner" element={<OwnerLayout />}>
                    <Route index element={<OwnerDashboard />} />
                    <Route path="inbox" element={<OwnerEmails />} />
                    <Route path="spaces" element={<Spaces />} />
                    <Route path="locations" element={<Locations />} />
                    <Route path="providers" element={<Providers />} />
                    <Route path="reservations" element={<Reservations />} />
                    <Route path="subscription" element={<Subscription />} />
                    <Route path="settings" element={<OwnerSettings />} />
                </Route>

                {/* Provider Routes */}
                <Route path="/provider" element={<ProviderLayout />}>
                    <Route index element={<ProviderDashboard />} />
                    <Route path="inbox" element={<ProviderEmails />} />
                    <Route path="spaces" element={<ProviderSpaces />} />
                    <Route path="spacedetail" element={<SpaceDetail />} />
                    <Route path="reservation" element={<Reservation />} />
                    <Route path="settings" element={<ProviderSettings />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
