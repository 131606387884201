// Import our custom CSS
import '../scss/styles.scss'
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

import PerfectScrollbar from "perfect-scrollbar";

// tooltip
// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})



// import Swiper JS
// import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

const flexSpaceApp = function () {
    const initSidebar = function () {
        const sidebar = document.querySelector('#sidebar');
        const sidebarPs = new PerfectScrollbar(sidebar.querySelector('.side-menu'), {
            wheelPropagation: true,
            minScrollbarLength: 20
        });


        // sidebar toggle
        var menu_btn = document.querySelector("#menu-btn");
        var sideMenu = document.querySelector("#sideMenu-btn");
        var body = document.querySelector("#app");
        var container = document.querySelector(".main-content");
        menu_btn.addEventListener("click", () => {
            body.classList.toggle("sidenav-toggled");
            container.classList.toggle("app-content");
        });
        sideMenu.addEventListener("click", () => {
            body.classList.toggle("sidenav-toggled");
            container.classList.toggle("app-content");
        });

        document.querySelectorAll('.side-menu-item').forEach(element => {
            element.addEventListener('click', (event) => {
                event.target.closest('li').classList.toggle('is-expanded')
            }, false);
        });

        // add body class for for toggle sidebar
        const event = ["mouseover", "mouseout"];
        function toggleClass(event) {
            const body = document.querySelector('body')
            event.type === "mouseover" && body.classList.contains('sidenav-toggled') ? body.classList.add('sidenav-toggled-open') : body.classList.remove('sidenav-toggled-open')
            sidebarPs.update()
        }
        event.forEach(ev => {
            document.querySelector("#sidebar").addEventListener(ev, toggleClass)
        })
    }

    // initBootstrap = function () {
    //     // tooltip
    //     var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    //     var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //         return new bootstrap.Tooltip(tooltipTriggerEl)
    //     })

    //     // popover
    //     var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    //     var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    //         return new bootstrap.Popover(popoverTriggerEl)
    //     })
    // };

    return {
        init: function () {
            // initBootstrap()
            // initSidebar()
        }
    }
}()
window.addEventListener("DOMContentLoaded", (event) => {
    flexSpaceApp.init();
});

window.flexSpaceApp = flexSpaceApp
window.bootstrap = bootstrap


