import { StripeOffcanvas } from "../../Offcanvas/admin/StripeOffcanvas"

export const PaymentMethod = (props) => {
    return (
        <>
            <div class="card-body">
                <h6 class="card-title">Stripe</h6>
                <form class="form border-top py-2">
                    <label class="form-label col-form-label" for="formControlInput1">Stripe secret key</label>
                    <div class="input-group mb-5">
                        <input class="form-control" id="formControlInput1" type="text" placeholder="* * * * * * * * * * * * *" data-bs-original-title="" title=""></input>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Stripe API Key</label>
                    <div class="input-group mb-5">
                        <input class="form-control" id="formControlInput1" type="text" placeholder="* * * * * * * * * * * * *" data-bs-original-title="" title=""></input>
                    </div>
                    <div class="actions position-absolute">
                        <div class="nav-item dropdown">
                            <a class="nav-link dropdown" href="javascript:void(0)" id="dropdown1" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <svg class="icon" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 6.33333C12.5833 6.33333 13.3333 5.58333 13.3333 4.66667C13.3333 3.75 12.5833 3 11.6667 3C10.75 3 10 3.75 10 4.66667C10 5.58333 10.75 6.33333 11.6667 6.33333ZM13.3333 10.5C13.3333 11.4167 12.5833 12.1667 11.6667 12.1667C10.75 12.1667 10 11.4167 10 10.5C10 9.58333 10.75 8.83333 11.6667 8.83333C12.5833 8.83333 13.3333 9.58333 13.3333 10.5ZM10 16.3333C10 15.4167 10.75 14.6667 11.6667 14.6667C12.5833 14.6667 13.3333 15.4167 13.3333 16.3333C13.3333 17.25 12.5833 18 11.6667 18C10.75 18 10 17.25 10 16.3333Z" />
                                </svg>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdown1">
                                <li><a className="dropdown-item" href="javascript:void(0)"
                                    tabindex="-1"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight">
                                    <i class="an an-write me-2"></i> Edit</a></li>
                                {/* <li><a class="dropdown-item" href="javascript:void(0)"><i class="an an-trash me-2"></i> Delete</a></li> */}
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
            <StripeOffcanvas />
        </>
    )
}