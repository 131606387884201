export const StripeOffcanvas = () => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end d-flex flex-column"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
                style={{ height: '100%' }}  // Remove vh and use full height flexibly
            >
                <div className="offcanvas-header border-bottom">
                    <h6 id="offcanvasRightLabel">Add/Edit Stripe</h6>
                    <button
                        type="button"
                        className="btn-close small"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>

                {/* Set the offcanvas body to flex layout with grow */}
                <div className="offcanvas-body d-flex flex-column flex-grow-1 justify-content-between px-6 border-bottom">

                    {/* Form that takes remaining space */}
                    <form className="form mb-9">
                        <div className="row mb-3">
                            <label className="form-label col-form-label bold" htmlFor="secretKeyInput">Stripe Secret Key</label>
                            <div className="position-relative">
                                <input
                                    className="form-control"
                                    id="secretKeyInput"
                                    type="password"
                                    placeholder="* * * * * * * * * * * *"
                                    title="">
                                </input>
                                {/* Eye icon */}
                                <i className="fa fa-eye position-absolute"
                                    style={{ top: '50%', right: '20px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                    onClick={() => togglePasswordVisibility('secretKeyInput')}>
                                </i>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="form-label col-form-label bold" htmlFor="apiKeyInput">Stripe API Key</label>
                            <div className="position-relative">
                                <input
                                    className="form-control"
                                    id="apiKeyInput"
                                    type="password"
                                    placeholder="* * * * * * * * * * * *"
                                    title="">
                                </input>
                                {/* Eye icon */}
                                <i className="fa fa-eye position-absolute"
                                    style={{ top: '50%', right: '20px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                    onClick={() => togglePasswordVisibility('apiKeyInput')}>
                                </i>
                            </div>
                        </div>
                    </form>

                    {/* Button that stays at the bottom */}
                    <div className="mt-auto">
                        <div className="d-grid">
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Function to toggle password visibility
function togglePasswordVisibility(inputId) {
    const input = document.getElementById(inputId);
    if (input.type === "password") {
        input.type = "text";
    } else {
        input.type = "password";
    }
}
