import logo from '../../assets/images/brand/logo-dark.png';
import profile from '../../assets/images/avatar/avatar2.png';
import { Link, Outlet } from "react-router-dom";

export const Navbar = (props) => {
    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-light  p-0  app-header header sticky ">
                <div class="container-fluid p-0 px-7 py-4 main-container">
                    <a aria-label="hide sidebar" id="menu-btn" class="app-sidebar-toggle menu-btn "
                        data-bs-toggle="sidebar" href="javascript:void(0)">
                        <i class="an an-menu-left"></i>
                    </a>
                    <a class="logo-horizontal navbar-brand d-lg-none" href="index.html">
                        <img src={logo} class="header-brand-img logo" alt="logo"></img>
                    </a>
                    <ul class="d-flex list-unstyled">
                        <li class="nav-item dropdown d-lg-none">
                            <a class="nav-link dropdown" href="javascript:void(0)" id="navbarProfile" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="/images/avatar/avatar2.png" alt="profile-user"
                                    class="avatar profile-user  cover-image"></img>
                            </a>

                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarProfile">
                                <li><a class="dropdown-item" href="javascript:void(0)">Profile</a></li>
                                <li class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="javascript:void(0)">Settings</a></li>
                                <li class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="javascript:void(0)">Logout</a></li>
                            </ul>
                        </li>
                        <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i class="an an-ellipsis-v"></i>
                        </button>
                    </ul>

                    {/* Navbar */}
                    <div class="navbar-collapse collapse" id="navbarMain">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">

                            <li class="nav-item dropdown notifications">
                                <a class="nav-link dropdown p-0" href="javascript:void(0)" id="navbarNotifications"
                                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg class="icon" width="32" height="32" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.7035 21.8484C21.7035 21.8484 20.2059 20.4457 20.1953 18.818C20.1953 16.0406 20.7191 14.5219 19.1512 12.9539C18.7469 12.5496 18.2336 12.3035 17.7484 12.1523C17.7941 11.1293 17.0383 10.5 16.2402 10.5C15.4422 10.5 14.6828 11.1293 14.7285 12.1559C14.2434 12.3035 13.7336 12.5496 13.3258 12.9539C11.7578 14.5254 12.2781 16.0441 12.2781 18.8215C12.3906 20.4457 10.7418 21.852 10.7418 21.852C10.7418 21.852 10 22.418 10 23.2652C10 24.9141 12.7844 26.2535 16.2227 26.2535C19.6609 26.2535 22.4453 24.9176 22.4453 23.2652C22.4453 22.418 21.7035 21.8484 21.7035 21.8484ZM16.2508 11.3578C16.6094 11.3578 16.8977 11.6461 16.8977 12.0047C16.8977 12.3633 16.6094 12.6516 16.2508 12.6516C15.8922 12.6516 15.6039 12.3633 15.6039 12.0047C15.6039 11.6461 15.8922 11.3578 16.2508 11.3578ZM13.0762 16.2586C12.8898 14.1844 14.0852 13.1719 15.0801 12.982C15.2734 13.2809 15.7691 13.534 16.2332 13.534C16.6973 13.534 17.2176 13.2703 17.4145 12.982C18.4129 13.1859 19.5168 14.1949 19.3938 16.2305C19.2812 18.7125 19.1336 19.6898 20.0547 21.1594C19.123 20.9168 17.6043 20.6074 16.2367 20.6074C14.9078 20.6074 13.2203 20.843 12.4961 21.1312C13.3328 19.7426 13.2168 18.9059 13.0762 16.2586ZM17.1789 21.6902C17.1789 22.2598 16.75 22.7238 16.2227 22.7238C15.6953 22.7238 15.2664 22.2598 15.2664 21.6902C15.2664 21.6586 15.2664 21.627 15.2699 21.5988C15.5758 21.5566 16.6129 21.532 17.1789 21.6094C17.1789 21.634 17.1789 21.6621 17.1789 21.6902ZM21.5945 23.2547C21.4961 24.2461 19.1969 25.3746 16.2367 25.3746C13.2766 25.3746 10.9387 24.2918 10.8789 23.2547C10.8086 21.9996 12.7105 21.8238 14.4754 21.6867C14.4754 22.5586 15.0484 23.4727 16.2332 23.4727C17.4953 23.4727 17.991 22.4742 17.991 21.6902C18.8102 21.8871 21.7105 22.0242 21.5945 23.2547Z" />
                                        <circle cx="21.5" cy="10.4375" r="3.5" fill="#00b0ff" />
                                    </svg>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarNotifications">
                                    <li class="border-bottom">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                            <div class="mt-1">
                                                <p class="fw-bold mb-1">New Application received</p>
                                                <span class="small">Location xyz meting room one </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="border-bottom">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                            <div class="mt-1">
                                                <p class="fw-bold mb-1">New Application received</p>
                                                <span class="small">Location xyz meting room one </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="border-bottom">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                            <div class="mt-1">
                                                <p class="fw-bold mb-1">New Application received</p>
                                                <span class="small">Location xyz meting room one </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item p-3 text-muted text-center" href="javascript:void(0)">
                                            View all Notification
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            {/* Profile */}
                            <li class="nav-item dropdown d-none d-lg-block">
                                <a class="nav-link dropdown d-flex gap-2 p-0 ms-4" href="javascript:void(0)" id="navbarProfile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="avatar avatar-sm ">
                                        <img src={profile} alt="profile-user"></img>
                                    </div>
                                    <div>
                                        <span class="small-bold text-dark">Sara Michael</span>
                                        <p class="mb-0 text-light extra-small">Fashion Designer</p>
                                    </div>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarProfile">
                                    <li><Link class="dropdown-item" to={props.profile}>Profile</Link></li>
                                    <li class="dropdown-divider"></li>
                                    <li><Link class="dropdown-item" to={props.setting}>Settings</Link></li>
                                    <li class="dropdown-divider"></li>
                                    <li><Link class="dropdown-item" to={props.logout}>Logout</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
            <Outlet />
        </>
    )
}