import React from 'react';
import { AdminSidebar } from '../components/common/sidebar/AdminSidebar';
import { Footer } from '../components/common/Footer';
import { Navbar } from '../components/common/Topbar';
const AdminLayout = () => {
    return (
        <div className="app sidebar-mini" id="app">
            <div className="page">
                <div className="page-main">
                    <Navbar setting="/admin/settings" />
                    <AdminSidebar
                        one="dashboard" onehref="/admin"
                        two="Inbox" twohref="/admin/inbox"
                        third="Owners" thirdhref="/admin/owners"
                        fourth="Subscriptions" fourthhref="/admin/subscriptions"
                        fifth="Metrics" fifthhref="/admin/metrics" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AdminLayout;
