
export const ReserveFrom = () => {
    return (
        <>
            <div class="card">
                <form class="form">
                    <div class="card-body p-4">
                        <h6 for="formControlSelect">Select Date/Time</h6>
                        <p>21 Aug 2024</p>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                <select class="bold form-select btn-square digits form-control form-select-sm" id="formControlSelect">
                                    <option>Move in</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <select class="bold form-select btn-square digits form-control form-select-sm" id="formControlSelect">
                                    <option>Move out</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <label class="form-label col-form-label">Upload Certification</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Upload" aria-label="Recipient's username" aria-describedby="basic-addon2"></input>
                            <span class="input-group-text bg-primary text-white" id="basic-addon2">Browse</span>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
