import certificate from '../../../../assets/images/certificates/image 45.png';
import certificate1 from '../../../../assets/images/certificates/image 46.png';
import certificate2 from '../../../../assets/images/certificates/image 47.png';

export const Certification = (props) => {
    return (
        <>
            <div class="card-body d-flex flex-column position-relative gap-3">
                <div className="page-header px-0 border-bottom">
                    <h6 class="card-title">Certification</h6>
                </div>
                <div className="row match-height">
                    <div className='px-2 col-sm-4 '>
                        <div className="mb-2 certificate-container gap-5">
                            <button className="close-button position-absolute">&times;</button>
                            <img src={certificate} className="certificate-image"></img>
                        </div>
                    </div>
                    <div className='px-2 col-sm-4 '>
                        <div className="mb-2 certificate-container">
                            <button className="close-button position-absolute">&times;</button>
                            <img src={certificate1} className="certificate-image"></img>
                        </div>
                    </div>
                    <div className='px-2 col-sm-4 '>
                        <div className="mb-2 certificate-container">
                            <button className="close-button position-absolute">&times;</button>
                            <img src={certificate2} className="certificate-image"></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}