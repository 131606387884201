// const dropdown = document.querySelector(".custom-dropdown .dropdown-select");
// const dropdownInput = document.querySelector(".custom-dropdown .dropdown-select input");
// const dropdownMenu = document.querySelector(".custom-dropdown .dropdown-menu");
// const dropdownOption = document.querySelectorAll(
//   ".custom-dropdown .dropdown-menu li"
// );

// const toggleDropdown = () => {
//   dropdown.classList.toggle("active");
// };

// dropdown.addEventListener("click", toggleDropdown);

// dropdownOption.forEach((option) => {
//   option.addEventListener("click", () => {
//     dropdownInput.value = option.textContent;
//     dropdown.classList.remove("active");
//   });
// });

// document.addEventListener("click", (event) => {
//   if (!dropdown.contains(event.target)) {
//     dropdown.classList.remove("active");
//   }
// });