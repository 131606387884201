import { LocationCard } from '../../../components/cards/LocationCard';
export const Locations = () => {
    return (
        <>
            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Locations</p>
                    <div class="d-flex gap-ten">
                        <butoon class="btn btn-primary btn-sm">Add Location</butoon>
                        <butoon class="btn btn-primary btn-sm">Manage Add-ons</butoon>
                    </div>
                </div>
                <div className="app-container">
                    <div className=" container-fluid px-0">
                        <div className="row match-height g-7">
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                            <div class="col-sm-6">
                                <LocationCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}