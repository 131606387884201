
// page header
export const PageHeader = (props) => (
    <div className="page-header shadow">
        <h6 className="bold">{props.pageNmae}</h6>
    </div>
)

// email user card
export const EmailUserCard = (props)=>(
<div className="card" key={props.idNo}>
    <div className="card-hover p-4 d-flex flex-row postion-relative">
    <div>
        <div class="avatar avatar-md border  rounded overflow-hidden me-3">
            <img src={props.img} alt="profile-user"/>
        </div>
    </div>
    {/* <span className="position-absolute avatar-status top-6"></span> */}
    <span className={`position-absolute ${props.status} top-6`}></span>
    <div>
        <div className="d-flex justify-content-between margin-five">
        <h6 className="mb-0">{props.name}</h6>
        <span className="small text-light">{props.time}</span>
        </div>
        <p className="mb-0">{props.mess}</p>
    </div>
    </div>
</div>
)