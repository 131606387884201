export const PackageOffcanvas = () => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header border-bottom">
                    <h6 id="offcanvasRightLabel">Add/Edit Package</h6>
                    <button
                        type="button"
                        className="btn-close small"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body d-flex flex-column flex-grow-1 justify-content-between px-6 border-bottom">
                    <form className="form mb-8">
                        <div className="card-body">
                            <div className="row mb-3">
                                <label className="form-label col-form-label bold" htmlFor="formControlInput1">Package Name</label>
                                <input className="form-control" id="formControlInput1" type="text" placeholder="Example: Business membership" title=""></input>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label bold">Package Type</label>
                                <div className="d-flex ">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="packageType" id="publicPackage" value="Public" />
                                        <label className="form-check-label" htmlFor="publicPackage">
                                            Public
                                        </label>
                                    </div>
                                    <div className="form-check mx-8">
                                        <input className="form-check-input" type="radio" name="packageType" id="privatePackage" value="Private" />
                                        <label className="form-check-label" htmlFor="privatePackage">
                                            Private
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row card p-3">
                                <h5 className="card-title text-center mb-4">Package Type</h5>
                                <div className="toggle-btn-group position-relative mb-4">
                                    <input type="radio" className="btn-check" name="packageDuration" id="monthlyPackage" autoComplete="off" />
                                    <label className="btn btn-outline-primary rounded-pill overlap-label left-label" htmlFor="monthlyPackage">Monthly</label>

                                    <input type="radio" className="btn-check" name="packageDuration" id="annualPackage" autoComplete="off" />
                                    <label className="btn btn-outline-primary rounded-pill overlap-label right-label" htmlFor="annualPackage">Annual</label>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label bold" htmlFor="priceInput">Price</label>
                                    <input className="form-control" id="priceInput" type="text" placeholder="$00.00" title=""></input>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label bold" htmlFor="locationsLimitInput">Locations Limit</label>
                                        <input className="form-control" id="locationsLimitInput" type="text" placeholder="1" title=""></input>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label bold" htmlFor="providersLimitInput">Providers Limit</label>
                                        <input className="form-control" id="providersLimitInput" type="text" placeholder="1" title=""></input>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label bold" htmlFor="spacesLimitInput">Spaces Limit</label>
                                        <input className="form-control" id="spacesLimitInput" type="text" placeholder="1" title=""></input>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label bold" htmlFor="subscriptionsLimitInput">Subscriptions Limit</label>
                                        <input className="form-control" id="subscriptionsLimitInput" type="text" placeholder="1" title=""></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card-body px-2 mt-2 mb-1">
                    <div className="d-grid">
                        <button type="button" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}
