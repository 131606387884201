import { PaymentCard } from '../../components/cards/PaymentCard';

export const Subscription = () => {
    return (
        <>

            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Subscriptions</p>
                </div>
                <div className="app-container">
                    <div className=" container-fluid">
                        <div className="row match-height">
                            <div class="col-sm-4 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-4 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-4 mt-3">
                                <PaymentCard />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-header">
                    <p className="bold">Credits Packages</p>
                </div>
                <div className="app-container">
                    <div className=" container-fluid">
                        <div className="row match-height">
                            <div class="col-sm-4 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-4 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-4 mt-3">
                                <PaymentCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}