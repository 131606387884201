export const LocationSetting = (props) => {
    return (
        <>
            <div class="card-body">
                <h6 class="card-title">Location Settings</h6>
                <form class="form border-top py-2">
                    <label class="form-label col-form-label" for="formControlInput1">Credits for 1 Hour</label>
                    <div class="input-group mb-5">
                        <input class="form-control" id="formControlInput1" type="text" placeholder="Example: 2Credits 1hour" data-bs-original-title="" title=""></input>
                    </div>
                    <h6 class="card-title">Space Settings</h6>
                    <label class="form-label col-form-label" for="formControlInput1">Standard booking time</label>
                    <div class="input-group mb-5">
                        <input type="text" class="form-control" placeholder="Example: 1hour" aria-label="Example: 1hour" aria-describedby="basic-addon2"></input>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Default time for buffer before/after</label>
                    <div class="input-group mb-5">
                        <input type="text" class="form-control" placeholder="Example: 30minutes" aria-label="Example: 30minutes" aria-describedby="basic-addon2"></input>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Appointment will be change before time</label>
                    <div class="input-group mb-5">
                        <input type="text" class="form-control" placeholder="Example: 1hour" aria-label="Example: 1hour" aria-describedby="basic-addon2"></input>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Show provider name on space</label>
                    <div class="input-group mb-5">
                        <div class="d-flex align-items-center mb-5">
                            <div class="form-check me-3">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="providerName"
                                    id="provider1"
                                    value="provider1"
                                    checked
                                />
                                <label class="form-check-label" for="provider1">
                                    Sarah P.
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="providerName"
                                    id="provider2"
                                    value="provider2"
                                />
                                <label class="form-check-label" for="provider2">
                                    S.P.
                                </label>
                            </div>
                        </div>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Sifely integration API key</label>
                    <div class="input-group mb-5">
                        <input type="text" class="form-control" placeholder="Example: API key" aria-label="Example: API key" aria-describedby="basic-addon2"></input>
                    </div>
                    <div class="mb-2">
                        <button class="btn btn-primary px-9" type="submit">Save Settings</button>
                    </div>
                </form>
            </div>
        </>
    )
}