
export const CardHover = (props) => {
    return (
        <>
            <div class="card " onClick={props.onClick} style={{ cursor: 'pointer' }}>
                <div class="card-body card-hover d-flex justify-content-between align-items-center">
                    <div class="content">
                        <p class="link-s mb-1">{props.description}</p>
                        <h5 class="mb-0">{props.number}</h5>
                    </div>
                    {props.icon}
                </div>
            </div>
        </>
    )
}