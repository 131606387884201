export const Card = (props) => {
    return (
        <>
            <div className="card">
                <div className="card-body card-hover d-flex justify-content-between">
                    <div className="content">
                        <p className="link-s mb-1">{props.description}</p>
                        <h5 className="mb-0">{props.number}</h5>
                    </div>
                    <span
                        className="badge-lg badge badge-circle text-bg-primary">
                        {props.icon}
                    </span>
                </div>
            </div>
        </>
    )
}


