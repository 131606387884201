import pic from "../../assets/images/avatar/image 58.png";
import AC from "../../assets/images/avatar/image 59.png";

export const Table4 = (props) => {
    return (
        <>
            <div className="card">
                <div class="row">
                    <table class="table table-hover">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Picture</th>
                                <th scope="col link-s">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">#01</th>
                                <td>AC</td>
                                <td>$5.00</td>
                                <td style={{
                                    width: '50px',
                                    height: '50px',
                                    // borderRadius: '50%',
                                    objectFit: 'cover'
                                }}>
                                    <img
                                        src={AC}
                                        alt="AC Product"

                                    />
                                </td>
                                <td>
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" fill="#B8BFCC" />
                                    </svg>
                                    <svg className="icon" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.5 4.5V3.25C15.5 2.85326 15.342 2.47097 15.0607 2.1897C14.7794 1.90843 14.3971 1.75 14 1.75H10C9.60289 1.75 9.22061 1.90843 8.93934 2.1897C8.65807 2.47097 8.5 2.85326 8.5 3.25V4.5H5.5C5.10289 4.5 4.72061 4.65843 4.43934 4.9397C4.15807 5.22097 4 5.60326 4 6V6.5C4 6.89711 4.15807 7.27939 4.43934 7.56066C4.72061 7.84193 5.10289 8 5.5 8H18.5C18.8971 8 19.2794 7.84193 19.5607 7.56066C19.842 7.27939 20 6.89711 20 6.5V6C20 5.60326 19.842 5.22097 19.5607 4.9397C19.2794 4.65843 18.8971 4.5 18.5 4.5H15.5ZM14 3.25H10V4.5H14V3.25ZM6 8.75V19.25C6 19.8467 6.23705 20.419 6.65901 20.841C7.08097 21.263 7.65326 21.5 8.25 21.5H15.75C16.3467 21.5 16.919 21.263 17.341 20.841C17.763 20.419 18 19.8467 18 19.25V8.75H6Z" />
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">#01</th>
                                <td>AC</td>
                                <td>$5.00</td>
                                <td style={{
                                    width: '10%',
                                    height: '10%',
                                    // borderRadius: '100%',
                                    objectFit: 'cover'
                                }}>
                                    <img
                                        src={pic}
                                        alt="AC Product"
                                    />
                                </td>
                                <td>
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" fill="#B8BFCC" />
                                    </svg>
                                    <svg className="icon" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.5 4.5V3.25C15.5 2.85326 15.342 2.47097 15.0607 2.1897C14.7794 1.90843 14.3971 1.75 14 1.75H10C9.60289 1.75 9.22061 1.90843 8.93934 2.1897C8.65807 2.47097 8.5 2.85326 8.5 3.25V4.5H5.5C5.10289 4.5 4.72061 4.65843 4.43934 4.9397C4.15807 5.22097 4 5.60326 4 6V6.5C4 6.89711 4.15807 7.27939 4.43934 7.56066C4.72061 7.84193 5.10289 8 5.5 8H18.5C18.8971 8 19.2794 7.84193 19.5607 7.56066C19.842 7.27939 20 6.89711 20 6.5V6C20 5.60326 19.842 5.22097 19.5607 4.9397C19.2794 4.65843 18.8971 4.5 18.5 4.5H15.5ZM14 3.25H10V4.5H14V3.25ZM6 8.75V19.25C6 19.8467 6.23705 20.419 6.65901 20.841C7.08097 21.263 7.65326 21.5 8.25 21.5H15.75C16.3467 21.5 16.919 21.263 17.341 20.841C17.763 20.419 18 19.8467 18 19.25V8.75H6Z" />
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}