
export const Search = () => {
    return (
        <>
            <form className="validated px-3">
                <div className="row position-relative">
                    <input
                        type="text"
                        className="form-control"
                        id="Input"
                        placeholder="Search.."
                        required
                    />
                    <i className="angle an an-search position-absolute flex-end top-50 translate-middle-y pe-3"></i>
                </div>
            </form>
        </>
    );
}

export const Searchbar = (props) => {
    return (
        <div className={`border form-control form-control-lg py-0 px-4 search-container w-100 ${props.classes}`}>
            <div>
                <svg role="button" width="32" className="icon" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.2197 21.8591L21.1421 19.7786L20.9575 19.9632L20.5121 19.5178C21.4557 18.3545 22.0212 16.8747 22.0212 15.263C22.0212 11.527 18.9942 8.5 15.2582 8.5C11.5221 8.5 8.49805 11.527 8.49805 15.2601C8.49805 18.9933 11.525 22.0202 15.2582 22.0202C16.8727 22.0202 18.3554 21.4547 19.5158 20.5111L19.9612 20.9565L19.7795 21.1382L21.8571 23.2187C22.0446 23.4062 22.2908 23.5 22.5369 23.5C22.7831 23.5 23.0292 23.4062 23.2167 23.2187C23.5947 22.8436 23.5947 22.2341 23.2197 21.8591ZM15.2582 21.0825C12.0437 21.0825 9.43573 18.4775 9.43573 15.2601C9.43573 12.0427 12.0437 9.43768 15.2582 9.43768C18.4727 9.43768 21.0806 12.0456 21.0806 15.2601C21.0806 18.4746 18.4756 21.0825 15.2582 21.0825Z" />
                </svg>
            </div>
            <input type="text" className="p-0 border-0 placeholder-lg" placeholder="Search provider..." />
        </div>
    );
}
