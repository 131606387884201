import React, { useState, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// Define default map center and style
const defaultCenter = { lat: 40.73061, lng: -73.935242 }; // Default location (New York City coordinates)

const containerStyle = {
    width: '100%',
    height: '400px',
};
export const Location = () => {
    const [selectedLocation, setSelectedLocation] = useState(null);

    // Callback to handle map click event to set location
    const onMapClick = useCallback((event) => {
        setSelectedLocation({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });
    }, []);
    return (
        <>
            <div class="card position-relative">
                <div class="card-body p-5">
                    <div>
                        <h5 class="mb-0">Location</h5>
                        <p>2124 13th Street Southeast, Washington DC, DC, 20020</p>
                    </div>
                    <div class="card-img">
                        <LoadScript googleMapsApiKey="">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={selectedLocation || defaultCenter}
                                zoom={selectedLocation ? 15 : 10}
                                onClick={onMapClick}
                            >
                                {/* Display a marker if a location is selected */}
                                {selectedLocation && <Marker position={selectedLocation} />}
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </div >
        </>
    )
}
