import { ReserveFrom } from '../../../components/forms/BookingForm';
import { SlideShow } from '../../../components/SlideShow';
import { Location } from '../../../components/cards/Location';
import { SpaceOverview } from '../../../components/cards/provider/SpaceOverview';
const SpaceDetail = () => {
    return (
        <>

            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Space Details</p>
                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">My Bookings</a>
                </div>
                <div className="app-container">
                    <div className="main-container container-fluid">
                        <div className="row match-height">
                            <div className="col-lg-12 col-xl-7">
                                <SlideShow />
                            </div>
                            <div className="col-lg-12 col-xl-5">
                                <ReserveFrom />
                            </div>
                            <div className="col-lg-12 col-xl-7">
                                <SpaceOverview />

                            </div>
                            <div className="col-lg-12 col-xl-5">
                                <Location />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Offcanvas /> */}

        </>
    )
}

export default SpaceDetail;