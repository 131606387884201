import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const revenueData = [
    { month: 'Jan', revenue: 400, subscriptions: 240 },
    { month: 'Feb', revenue: 300, subscriptions: 200 },
    { month: 'Mar', revenue: 500, subscriptions: 300 },
    { month: 'Apr', revenue: 700, subscriptions: 400 },
    { month: 'May', revenue: 600, subscriptions: 380 },
    { month: 'Jun', revenue: 400 },
    { month: 'Jul', revenue: 300, subscriptions: 420 },
    { month: 'Aug', revenue: 900, subscriptions: 470 },
    { month: 'Sep', revenue: 950, subscriptions: 480 },
    { month: 'Oct', revenue: 450, subscriptions: 500 },
    { month: 'Nov', revenue: 750, subscriptions: 550 },
    { month: 'Dec', revenue: 400, subscriptions: 600 },
];

const MyLineCharts = () => {
    return (
        <div className="card">
            <div className="card-body px-3">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="bold">Monthly Revenue</p>
                    <div className="d-flex align-items-start">
                        <p className="bold me-3">$221,267</p>
                        <button
                            style={{
                                border: '1px solid #23BABC',
                                color: '#23BABC',
                                backgroundColor: 'transparent',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                fontWeight: 'bold'
                            }}
                        >
                            &#x2191; 22%
                        </button>
                    </div>
                </div>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                        width={500}
                        height={300}
                        data={revenueData}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line type="monotone" dataKey="revenue" stroke="#23BABC" activeDot={{ r: 8 }} />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend verticalAlign="top" align="right" /> */}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MyLineCharts;
