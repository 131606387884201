import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import '../assets/scss/components/_calendar.scss';

const MyFullCalendar = () => {
    // State to manage the events
    const [events, setEvents] = useState([
        { id: 1, title: 'Event 1', date: '2024-08-01' },
        { id: 2, title: 'Event 2', date: '2024-08-02' }
    ]);

    // State to manage the new task input
    const [newTask, setNewTask] = useState({ title: '', date: '' });

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTask((prev) => ({ ...prev, [name]: value }));
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (newTask.title && newTask.date) {
            // Add a new event
            setEvents((prevEvents) => [
                ...prevEvents,
                { id: prevEvents.length + 1, title: newTask.title, date: newTask.date }
            ]);
            // Reset the input fields
            setNewTask({ title: '', date: '' });
        }
    };

    return (
        <div className='calendar-container card text-light p-7'>
            {/* <h1>FullCalendar Example</h1>

            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Task Title"
                    value={newTask.title}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="date"
                    name="date"
                    value={newTask.date}
                    onChange={handleInputChange}
                    required
                />
                <button type="submit">Add Task</button>
            </form> */}

            {/* FullCalendar Component */}
            <div>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    events={events}
                />
            </div>

        </div>
    );
};

export default MyFullCalendar;
