import '../../../assets/js/main';
import profile from '../../../assets/images/avatar/avatar2.png';

const Profile = () => {
    return (
        <>
            <div className="main-content app-content">
                <div class="profile-header">
                    <div class="profile-avatar">
                        <div class="avatar avatar-lg rounded-circle border avatar-do-not-disturb me-3">
                            <img src={profile} alt="profile-user"></img>
                        </div>
                        <h4 class="profile-title">Linnie Daniel
                            <span>Quality Assurance</span>
                        </h4>
                    </div>
                </div>
                <div class="follow-followers">
                    <div class="facts">
                        <div class="follow">
                            <h4>10275<span>Follow</span></h4>
                        </div>
                        <div class="followers">
                            <h4>21241<span>Followers</span></h4>
                        </div>
                    </div>

                    <div class="action">
                        <a href="javascript:void(0);" class="btn btn-outline-primary">Primary</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;