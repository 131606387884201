import React, { useState } from 'react';

export const LeftCard = (props) => {
    // Manage active link state
    const [activeLink, setActiveLink] = useState(null);

    // Handle link click and set the active link
    const handleClick = (link, cardName) => {
        setActiveLink(link);
        props.onCardClick(cardName); // Trigger the parent function to change the active card
    };

    return (
        <div className="card-body p-0 py-4">
            <ul className="list-unstyled">
                <li className={`border-bottom py-2 ${activeLink === 'one' ? 'active' : ''}`}>
                    <a
                        className="text-decoration-none link-s text-dark"
                        href="javascript:void(0)"
                        onClick={() => handleClick('one', 'payment')}
                    >
                        {props.one}
                    </a>
                </li>
                <li className={`border-bottom py-2 ${activeLink === 'two' ? 'active' : ''}`}>
                    <a
                        className="text-decoration-none link-s text-dark"
                        href="javascript:void(0)"
                        onClick={() => handleClick('two', 'profile')}
                    >
                        {props.two}
                    </a>
                </li>
                <li className={`border-bottom py-2 ${activeLink === 'three' ? 'active' : ''}`}>
                    <a
                        className="text-decoration-none link-s text-dark"
                        href="javascript:void(0)"
                        onClick={() => handleClick('three', 'subscription')}
                    >
                        {props.three}
                    </a>
                </li>
                <li className={`border-bottom py-2 ${activeLink === 'four' ? 'active' : ''}`}>
                    <a
                        className="text-decoration-none link-s text-dark"
                        href="javascript:void(0)"
                        onClick={() => handleClick('four', 'certification')}
                    >
                        {props.four}
                    </a>
                </li>
            </ul>
        </div>
    );
};
