import img from "../assets/images/logo.png"
const ResetPassword =()=>{
    return(
        <div className="auth-page w-100 bg-resetPassword d-flex flex-column flex-lg-row justify-content-center justify-content-lg-evenly align-items-center">
    <div className="left-logo-div">
        <div className="logo w-100">
            <img src={img} alt="background-image"/>
        </div>
        <p className="link pt-6 pb-7 text-white mx-auto mb-0">We Know your needs ! Lorem ipsum odor amet, consectetuer adipiscing elit. Viverra hendrerit proin taciti vestibulum erat.</p>
    </div>
    <div className="content">
        <div className="transparaent-card d-flex flex-column p-8 rounded-4 w-100">
            <h4 className="mb-0">Welcome to Flex Space</h4>
            <p className="mb-4 link">Create your password</p>
            <div> 
                <div className="d-flex justify-content-between align-items-center">
                    <label for="password" className="form-label col-form-label label-md text-dark">Password</label>
                    <span className="text-danger label-md error">invalid</span>
                </div>
                <div className="border form-control form-control-lg password-container w-100">
                    <input type="password" id="password" className="p-0 border-0 placeholder-lg" value="password" autocomplete="new-password"/> 
                    <span className="password-icon">
                        <i className="fa-solid fa-eye-slash"></i>
                        <i className="fa-solid fa-eye"></i>
                    </span>
                </div>
            </div>
            <div> 
                <div className="d-flex justify-content-between align-items-center">
                    <label for="password" className="form-label col-form-label label-md text-dark">Password</label>
                     {/* <span className="text-danger error label-md">invalid</span> */}
                </div>
                <div className="border form-control form-control-lg password-container w-100">
                    <input type="password" id="password" className="p-0 border-0 placeholder-lg" value="password" autocomplete="new-password"/> 
                    <span className="password-icon">
                        <i className="fa-solid fa-eye-slash"></i>
                        <i className="fa-solid fa-eye"></i>
                    </span>
                </div>
            </div>
            <button className="btn btn-primary btn-lg mt-4 link">Confirm</button>
        </div>

        <div className="transparaent-card p-8 mt-7 py-7 rounded-4 w-100">
            <div className=" d-flex justify-content-between align-items-center py-7">
                <button className="btn link p-0 text-secondary text-start">If you have any issues with creating or reseting your password, please contact your administrator.</button>
               <button className="btn btn-secondary btn-sm text-nowrap">Contact</button>
            </div>
        </div>
    </div>
</div>
    )
}
export default ResetPassword;