import React from 'react';
import { OwnerSidebar } from '../components/common/sidebar/OwnerSidebar';
import { Footer } from '../components/common/Footer';
import { Navbar } from '../components/common/Topbar';
const OwnerLayout = () => {
    return (
        <div className="app sidebar-mini" id="app">
            <div className="page">
                <div className="page-main">
                    <Navbar setting="/owner/settings" />
                    <OwnerSidebar
                        one="dashboard" onehref="/owner"
                        two="Inbox" twohref="/owner/inbox"
                        third="Reservations" thirdhref="/owner/reservations"
                        fourth="Providers" fourthhref="/owner/providers"
                        fifth="Spaces" fifthhref="/owner/locations"
                        sixth="Subscription" sixthhref="/owner/subscription"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default OwnerLayout;
