export const Table8 = (props) => {
    return (
        <>
            <div className="card-body">
                <div className="row">
                    <table className="table table-id">
                        <thead className="table-light">
                            <tr>
                                <th scope="col link-s">ID</th>
                                <th scope="col link-s">{props.request}</th>
                                <th scope="col link-s">{props.space}</th>
                                <th scope="col link-s">{props.time}</th>
                                <th scope="col link-s">{props.phone}</th>
                                <th scope="col link-s">{props.email}</th>
                                <th scope="col link-s">{props.payment}</th>
                                <th scope="col link-s">{props.status}</th>
                                <th scope="col link-s">{props.actions}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row">{props.id}</td>
                                <td className="text-dark medium">{props.requested}</td>
                                <td>{props.name}</td>
                                <td>{props.number}</td>
                                <td>
                                    <span className="badge rounded-pill badge-primary-light">{props.paystatus}</span>
                                </td>
                                <td>{props.pay}</td>
                                <td>
                                    <span className="badge rounded-pill badge-primary-light">{props.type}</span>
                                </td>
                                <td>{props.date}</td>
                                <td>
                                    <svg className="icon"
                                        width="19"
                                        height="12"
                                        viewBox="0 0 19 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.6344 6.2188C12.6344 6.62817 12.5563 7.0188 12.4125 7.37817C12.275 6.96255 11.8844 6.66255 11.425 6.66255C10.85 6.66255 10.3844 7.12817 10.3844 7.70317C10.3844 8.2563 10.8156 8.70942 11.3562 8.7438C10.8375 9.12505 10.1969 9.35005 9.50625 9.35005C7.77813 9.35005 6.375 7.94692 6.375 6.2188C6.375 4.48755 7.775 3.08442 9.50625 3.08442C11.2344 3.08442 12.6344 4.48755 12.6344 6.2188ZM9.5 1.11255C12.8813 1.11255 15.6469 3.58442 17.3438 6.00005C16.2031 7.80942 13.2844 10.8844 9.50937 10.8844C5.83437 10.8844 3.05 7.9438 1.65 5.98755C2.55625 4.65942 5.35938 1.11255 9.5 1.11255ZM9.5 0.112549C3.79063 0.112549 0.46875 5.98755 0.46875 5.98755V6.00005C0.46875 6.00005 3.88437 11.8875 9.50937 11.8875C15.1219 11.8875 18.4125 6.20317 18.5312 5.9938C18.4125 5.79067 15.0125 0.112549 9.5 0.112549Z" /></svg>
                                    <svg className="icon"
                                        width="19"
                                        height="22"
                                        viewBox="0 0 21 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M11.6667 6.83333C12.5833 6.83333 13.3333 6.08333 13.3333 5.16667C13.3333 4.25 12.5833 3.5 11.6667 3.5C10.75 3.5 10 4.25 10 5.16667C10 6.08333 10.75 6.83333 11.6667 6.83333ZM13.3333 11C13.3333 11.9167 12.5833 12.6667 11.6667 12.6667C10.75 12.6667 10 11.9167 10 11C10 10.0833 10.75 9.33333 11.6667 9.33333C12.5833 9.33333 13.3333 10.0833 13.3333 11ZM10 16.8333C10 15.9167 10.75 15.1667 11.6667 15.1667C12.5833 15.1667 13.3333 15.9167 13.3333 16.8333C13.3333 17.75 12.5833 18.5 11.6667 18.5C10.75 18.5 10 17.75 10 16.8333Z" /></svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}