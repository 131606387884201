import MyFullCalendar from '../../components/Calendar';
import { LocCard } from '../../components/cards/LocCard';
export const Reservations = () => {
    return (
        <>
            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Booking</p>
                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">Add New</a>
                </div>
                <div className="app-container">
                    <div className="main-container container-fluid">
                        <div className="row">
                            <div className="col-sm-4 mt-3">
                                <LocCard />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <LocCard />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <LocCard />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <LocCard />
                            </div>
                        </div>
                        <div className="row match-height">
                            <div className="col-xl-12 col-lg-12">
                                <MyFullCalendar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}