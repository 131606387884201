import ImageGallery from "react-image-gallery";
import sofa from '../assets/images/cards/image.png';
import location from '../assets/images/cards/office.png';

const images = [
  {
    original: sofa,
    thumbnail: sofa,
  },
  {
    original: location,
    thumbnail: location,
  },
  {
    original: sofa,
    thumbnail: sofa,
  },
  {
    original: location,
    thumbnail: location,
  },
];

export const SlideShow = () => {

  return (
    <ImageGallery items={images} />
  )

}