export const SpecialtyCard = (props) => {
    return (
        <>
            <div class="card-body d-flex flex-column position-relative gap-3">
                <div className="page-header px-0 border-bottom">
                    <h6 class="card-title">Specialty Settings</h6>
                    <a
                        className="btn btn-primary btn-sm"
                        tabIndex="-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                    >
                        Add Specialty
                    </a>
                </div>
                <div className="row match-height">
                    <ul class="list-unstyled">
                        <li class="border-bottom py-2 mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <a
                                    class="text-decoration-none link-s text-dark"
                                    href="javascript:void(0)"
                                >
                                    Message Therapist
                                </a>
                                <div className="d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" />
                                    <a
                                        class="text-decoration-none link-s text-dark ms-4"
                                        href="javascript:void(0)"
                                    >
                                        Certificate Required
                                    </a>
                                    <svg className="ms-9" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" fill="#B8BFCC" />
                                    </svg>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom py-2 mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <a
                                    class="text-decoration-none link-s text-dark"
                                    href="javascript:void(0)"
                                >
                                    Chiropractor
                                </a>
                                <div className="d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" />
                                    <a
                                        class="text-decoration-none link-s text-dark ms-4"
                                        href="javascript:void(0)"
                                    >
                                        Certificate Required
                                    </a>
                                    <svg className="ms-9" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" fill="#B8BFCC" />
                                    </svg>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom py-2 mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <a
                                    class="text-decoration-none link-s text-dark"
                                    href="javascript:void(0)"
                                >
                                    Practitioner
                                </a>
                                <div className="d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" />
                                    <a
                                        class="text-decoration-none link-s text-dark ms-4"
                                        href="javascript:void(0)"
                                    >
                                        Certificate Required
                                    </a>
                                    <svg className="ms-9" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" fill="#B8BFCC" />
                                    </svg>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom py-2 mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <a
                                    class="text-decoration-none link-s text-dark"
                                    href="javascript:void(0)"
                                >
                                    Specialty 4
                                </a>
                                <div className="d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" />
                                    <a
                                        class="text-decoration-none link-s text-dark ms-4"
                                        href="javascript:void(0)"
                                    >
                                        Certificate Required
                                    </a>
                                    <svg className="ms-9" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" fill="#B8BFCC" />
                                    </svg>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
