import { SpaceCard } from '../../../components/cards/provider/SpaceCard';
import { SpaceCards } from '../../../components/cards/provider/Spacecards';
import { Pagination } from '../../../components/Pagination';
export const ProviderSpaces = () => {
    return (
        <>
            <div className="main-content app-content">
                <div className="app-container">
                    <div className="main-container container-fluid">
                        <div className="row match-height">
                            <div className="row match-height m-0 p-0">
                                <div className="page-header">
                                    <p className="bold">Spaces</p>
                                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">My Bookings</a>
                                </div>
                                <h1 className="bold">All Available Locations</h1>
                                <div className="col-lg-12 col-xl-4">
                                    <SpaceCard />
                                </div>
                                <div className="col-lg-12 col-xl-4">
                                    <SpaceCard />
                                </div>
                                <div className="col-lg-12 col-xl-4">
                                    <SpaceCard />
                                </div>
                            </div>
                            <div className="row match-height m-0 p-0">
                                <div className="page-header">
                                    <p className="bold">120 Available Spaces</p>
                                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">Calendar view</a>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                            </div>
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
            {/* <Offcanvas /> */}
        </>
    )
}