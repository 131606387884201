export const OwnerOffcanvas = () => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header border-bottom">
                    <h6 id="offcanvasRightLabel">Add/Edit Owner</h6>
                    <button
                        type="button"
                        className="btn-close small"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body d-flex flex-column flex-grow-1 justify-content-between px-6 border-bottom">
                    <form class="form mb-8">
                        <div class="card-body">
                            <div class="row mb-3">
                                <label class="form-label col-form-label bold" for="formControlInput1">First Name</label>
                                <input class="form-control" id="formControlInput1" type="email" placeholder="Example: John" data-bs-original-title="" title=""></input>
                            </div>
                            <div class="row mb-3">
                                <label class="form-label col-form-label bold" for="formControlInput1">Last Name</label>
                                <input class="form-control" id="formControlInput1" type="email" placeholder="Example: Smith" data-bs-original-title="" title=""></input>
                            </div>
                            <div class="row mb-3">
                                <label class="form-label col-form-label bold" for="formControlInput1">Email address</label>
                                <input class="form-control" id="formControlInput1" type="email" placeholder="name@example.com" data-bs-original-title="" title=""></input>
                            </div>
                            <div class="row mb-3">
                                <label class="form-label col-form-label bold" for="formControlInput1">Phone Number</label>
                                <input class="form-control" id="formControlInput1" type="email" placeholder="+1 123-456-7890" data-bs-original-title="" title=""></input>
                            </div>
                            <div>
                                <label class="form-label col-form-label bold" for="inputPassword2">Domain name</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-primary text-white">https://</span>
                                    <input type="text" class="form-control"
                                        aria-label="Amount (to the nearest dollar)"></input>
                                    <span class="input-group-text bg-primary text-white">.flexspace.com</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="form-label col-form-label bold" for="inputPassword2">City/State</label>
                                <input class="form-control" id="inputPassword2" type="password" placeholder="City/State name" data-bs-original-title="" title=""></input>
                            </div>
                            <div class="row mb-3">
                                <label class="form-label col-form-label bold" for="formControlSelect9">Subscription Plan</label>
                                <select class="form-select digits" id="formControlSelect9">
                                    <option>Select</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            {/* <div class="row">
                                <label class="form-label bold" for="formControlTextarea4">Example textarea</label>
                                <textarea class="form-control" id="formControlTextarea4" rows="3"></textarea>
                            </div> */}
                        </div>
                    </form>
                </div>
                <div class="card-body px-2 mt-2 mb-1">
                    <div class="d-grid">
                        <button type="button" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}