
import { Card } from '../../components/cards/Card';
import { SpaceCard } from '../../components/cards/provider/SpaceCard';
import { SpaceCards } from '../../components/cards/provider/Spacecards';
import { useNavigate } from 'react-router-dom';

const ProviderDashboard = () => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate('/history');
    };

    return (
        <>
            <div className="main-content app-content">
                <div className="app-container">
                    <div className="main-container container-fluid">
                        <div className="row">
                            <div className="col-sm-4 mt-3">
                                <Card description="My bookings" number="3" onClick={handleCardClick} />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <Card description="Available Credits" number="180" />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <Card description="Upcoming Bookings" number="1" />
                            </div>
                        </div>
                        <div className="row match-height">
                            <div className="row match-height m-0 p-0">
                                <div className="page-header">
                                    <p className="bold">Upcoming bookings</p>
                                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">See All</a>
                                </div>
                                <div className="col-lg-12 col-xl-4">
                                    <SpaceCard />
                                </div>
                                <div className="col-lg-12 col-xl-4">
                                    <SpaceCard />
                                </div>
                                <div className="col-lg-12 col-xl-4">
                                    <SpaceCard />
                                </div>
                            </div>
                            <div className="row match-height m-0 p-0">
                                <div className="page-header">
                                    <p className="bold">Available Spaces</p>
                                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">Explore All</a>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <SpaceCards />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <Offcanvas /> */}

        </>
    )
}

export default ProviderDashboard;