import { Table4 } from "../../tables/Table4"

export const AddonsCard = (props) => {
    return (
        <>
            <div className="card-body d-flex flex-column position-relative gap-3">
                <div className="page-header px-0">
                    <h6 class="card-title">Addons Settings</h6>
                    <a
                        className="btn btn-primary btn-sm "
                        // href="javascript:void(0)"
                        tabindex="-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                    >
                        Add add-ons
                    </a>
                </div>
                <div className="row match-height">
                    <Table4 />
                </div>
            </div>
        </>
    )
}