import { DocumentField } from "./forms/inputField";
import { Searchbar } from "./forms/Search";
import img from "../assets/images/cards/image.png";
import { EmailUserCard } from "./components";

export const EmailInbox = () => {
    return (
        <div className="container-fluid px-0">
            <div className="d-flex gap-4">
                <div className="card info-sider py-7 px-0">
                    <div className="card-header py-0 px-4">
                        <Searchbar classes={["margin-ten"]} />
                        <h6>Inbox <span className="small text-primary">4 &nbsp;</span><span className="small">New</span></h6>
                    </div>
                    <div className="card-body scrollbar py-0 px-4">
                        {Array.from({ length: 8 }, (item, id) => (
                            <EmailUserCard
                                key={id} // Add a key prop to avoid key warnings
                                idNo={id}
                                img={img}
                                status="avatar-status"
                                name="William"
                                mess="Lorem ipsum dolor sit amet sed do consectetur adipiscing elit,"
                            />
                        ))}
                    </div>
                </div>

                <div className="card py-7 d-flex flex-column gap-ten info-details">
                    <div className="card-header py-4 px-7">
                        <h6 className="mb-1">Location name</h6>
                        <p className="mb-4">Space lorem ipsum dolor sit amet sed do consectetur adipiscing elit,</p>
                    </div>
                    <div className="card-body scrollbar d-flex flex-column gap-ten px-7 py-0">
                        <div className="d-flex flex-column gap-ten">
                            <div className="d-flex gap-ten align-items-center">
                                <div className="avatar avatar-md border rounded overflow-hidden">
                                    <img src={img} alt="profile-user" />
                                </div>
                                <h6 className="mb-0">David James</h6>
                            </div>
                            <div className="email-pic">
                                <img className="w-100" src={img} alt="profile-user" />
                            </div>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>

                        <div className="d-flex flex-column gap-ten">
                            <div className="d-flex pt-7 gap-ten align-items-center">
                                <div className="avatar avatar-md border rounded overflow-hidden">
                                    <img src={img} alt="profile-user" />
                                </div>
                                <h6 className="mb-0">Admin</h6>
                            </div>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>

                        <div className="d-flex flex-column gap-ten">
                            <div className="d-flex pt-7 gap-ten align-items-center">
                                <div className="avatar avatar-md border rounded overflow-hidden">
                                    <img src={img} alt="profile-user" />
                                </div>
                                <h6 className="mb-0">David James</h6>
                            </div>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>

                        <form className="py-4 d-flex flex-column gap-4">
                            <h6 className="mb-0 py-4">Reply</h6>
                            <div className="row mx-0">
                                <label className="form-label col-form-label label-md text-dark px-0" htmlFor="emailTextarea">Message</label>
                                <textarea className="form-control" id="emailTextarea" rows="6"></textarea>
                            </div>
                            <DocumentField iconClass={["icon"]} />
                            <button className="btn btn-primary btn-lg w-25" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

