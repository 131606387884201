import profile from '../../../../assets/images/avatar/avatar2.png';
export const ProfileCard = (props) => {
    return (
        <>
            <div class="card-body d-flex flex-column position-relative gap-4">
                <svg class="icon position-absolute" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0844 3.19368C10.1688 3.0593 9.54062 2.4718 8.67188 1.87493C7.80625 1.27493 7.02813 0.896804 6.93125 1.02493L0.6125 10.7374L0.3 11.1968C0.45625 12.0312 2.1 13.3531 3.44062 13.2812L3.7875 12.7906L10.0844 3.19368ZM8.22188 1.91555C8.2375 1.89055 8.37813 1.9593 8.5375 2.06868C8.69375 2.17805 8.80937 2.2843 8.79375 2.3093C8.77812 2.3343 8.63437 2.26555 8.47812 2.15618C8.31875 2.04368 8.20625 1.93743 8.22188 1.91555ZM14 14.9999H4.08125L5.62187 12.7499H14V14.9999ZM0.0625 11.6281L0 14.9906L3.20312 13.7124C2.74062 13.6062 1.94375 13.4687 1.27813 13.0156C0.665625 12.5999 0.3125 11.9874 0.0625 11.6281Z" />
                </svg>

                <div class="d-flex align-items-center gap-4">
                    <div class="avatar avatar-xl rounded-circle border avatar-do-not-disturb me-0">
                        <img src={profile} alt="profile-user" />
                    </div>
                    <div>
                        <h5 class="mb-0">Sara Michael</h5>
                        <p class="mb-0">Fashion Designer</p>
                    </div>
                </div>
                <div class="d-flex flex-column gap-4">
                    <div class="d-flex align-items-center">
                        <h6>Email:</h6>
                        <p class="mb-0">sara@gmail.com</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <h6>Phone Number</h6>
                        <p class="mb-0">+123 456 789</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <h6>Password</h6>
                        <p class="mb-0">*************</p>
                    </div>
                    <div>
                        <h6>Maling Address:</h6>
                    </div>
                    <div class="d-flex align-items-center">
                        <h6>Country:</h6>
                        <p class="mb-0">United State</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <h6>State:</h6>
                        <p class="mb-0">Virginia</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <h6>Zip Code:</h6>
                        <p class="mb-0">+02401</p>
                    </div>
                </div>
            </div>
        </>
    )
}