import '../../../assets/js/main';
import logo from '../../../assets/images/brand/logo.png';

const Register = () => {
    return (
        <>
            <div>
                <div class="auth-page w-100 bg-signup d-flex flex-column justify-content-center align-items-center">
                    <div class="content">
                        <div class="logo text-center w-100">
                            <img src={logo} alt="background-image"></img>
                        </div>
                        <p class="link pt-6 pb-7 text-white text-center mx-auto mb-0">We Know your needs ! Lorem ipsum odor amet, consectetuer adipiscing elit. Viverra hendrerit proin taciti vestibulum erat.</p>
                        <div class="transparaent-card d-flex flex-column p-8 rounded-4 w-100">
                            <h4 class="mb-0">Signup</h4>
                            <p class="mb-4 link">Welcome to Flex Space</p>
                            <div class="d-flex gap-4 justify-content-between align-items-center">
                                <div class="w-100">
                                    <label for="fname" class="form-label col-form-label label-md text-dark">First name</label>
                                    <input type="text" class="form-control form-control-md placeholder-lg" placeholder="Example: Johan" id="fname"></input>
                                    <div id="InputMd" class="form-text">We'll never share your data.</div>
                                </div>
                                <div class="w-100">
                                    <label for="lname" class="form-label col-form-label label-md text-dark">Last name</label>
                                    <input type="text" class="form-control form-control-md placeholder-lg" placeholder="Example: Johan" id="lname"></input>
                                    <div id="InputMd" class="form-text">We'll never share your data.</div>
                                </div>
                            </div>
                            <div>
                                <label for="email" class="form-label col-form-label label-md text-dark">Email</label>
                                <input type="email" class="form-control form-control-md placeholder-lg" placeholder="example@gmail.com" id="email"></input>
                                <div id="InputMd" class="form-text">We'll never share your data.</div>
                            </div>
                            <div>
                                <label for="phone" class="form-label col-form-label label-md text-dark">Phone Number</label>
                                <input type="number" class="form-control form-control-md placeholder-lg" placeholder="+123 456 789" id="phone"></input>
                                <div id="InputMd" class="form-text">We'll never share your data.</div>
                            </div>
                            <div class="input-group">
                                <div class="w-100 d-flex justify-content-between align-items-center">
                                    <label for="domain" class="form-label col-form-label label-md text-dark">Domain name</label><span class="text-danger">domain is not available</span>
                                </div>
                                <span class="btn btn-primary btn-md">https://</span>
                                <input type="text" class="form-control form-control-md placeholder-lg" id="domain"></input>
                                <span class="btn btn-primary btn-md">.flexspace.com</span>
                            </div>
                            <p class="mb-0 link pt-7 text-black">By clicking on submit button you must be agree with our <a href="#" class="text-blue text-decoration-none">terms & conditions</a> & <a href="#" class="text-blue text-decoration-none">privacy policy</a>  </p>
                            <button class="btn btn-primary btn-lg mt-4 link">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;