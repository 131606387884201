import { SpaceCard } from '../../../components/cards/SpaceCard';

export const Spaces = () => {
    return (
        <>
            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Spaces</p>
                    <div>
                        <a href="javascript:void(0);" className="btn btn-primary btn-sm mr-1">Add Space</a>
                        <a href="javascript:void(0);" className="btn btn-primary btn-sm ml-1">Create Add-ons</a>
                    </div>
                </div>
                <div className="app-container">
                    <div className=" container-fluid">
                        <div className="row match-height">
                            <SpaceCard />
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                            <div class="col-sm-6 mt-3">
                                <SpaceCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}