export const Pagination = () => {
    return (
        <>
            <div class="col-xl-4 col-lg-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <ul class="pagination pagination-circle">
                                <li class="page-item previous disabled">
                                    <a href="javascript:void(0)" class="page-link">
                                        <i class="an an-angle-left"></i>
                                    </a>
                                </li>
                                <li class="page-item "><a href="javascript:void(0)" class="page-link">1</a></li>
                                <li class="page-item active"><a href="javascript:void(0)" class="page-link">2</a></li>
                                <li class="page-item "><a href="javascript:void(0)" class="page-link">3</a></li>
                                <li class="page-item "><a href="javascript:void(0)" class="page-link">4</a></li>
                                <li class="page-item "><a href="javascript:void(0)" class="page-link">5</a></li>
                                <li class="page-item "><a href="javascript:void(0)" class="page-link">6</a></li>
                                <li class="page-item next">
                                    <a href="javascript:void(0)" class="page-link">
                                        <i class="an an-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}