export const ThemeSetting = (props) => {
    return (
        <>
            <div class="card-body">
                <h6 class="card-title">Theme Settings</h6>
                <form class="form border-top py-2">
                    <label class="form-label col-form-label" for="formControlInput1">Site Title</label>
                    <div class="input-group mb-5">
                        <input class="form-control" id="formControlInput1" type="text" placeholder="Flex Space" data-bs-original-title="" title=""></input>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Upload Logo</label>
                    <div class="input-group mb-5">
                        <input type="file" class="form-control" id="fileUpload" aria-label="Upload Fav" aria-describedby="basic-addon2"></input>
                            <span class="input-group-text bg-primary text-white" id="basic-addon2">Browse</span>
                    </div>

                    <label class="form-label col-form-label" for="formControlInput1">Upload Fav Icon</label>
                    <div class="input-group mb-5">
                        <input type="file" class="form-control" placeholder="Upload" aria-label="Upload Fav" aria-describedby="basic-addon2"></input>
                        <span class="input-group-text bg-primary text-white" id="basic-addon2">Browse</span>
                    </div>
                    <label class="form-label col-form-label" for="formControlInput1">Theme Color</label>
                    <div class="input-group mb-5">
                        <div class="col-1">
                            <input class="form-control form-control-color" type="color" value="#23BABC" data-bs-original-title="" title=""></input>
                        </div>
                        <div class="col-11">
                            <input type="text" class="ml-1 form-control" placeholder="Example: #23BABC" aria-label="Username" aria-describedby="basic-addon1"></input></div>
                    </div>
                    <div class="mb-2">
                        <button class="btn btn-primary px-9" type="submit">Save Settings</button>
                    </div>
                </form>
            </div>
        </>
    )
}