import React, { useState } from 'react';
import { LeftCard } from '../../components/cards/AdminSetting/LeftCard';
import { ProfileCard } from '../../components/cards/AdminSetting/ProfileCard';
import { ThemeSetting } from '../../components/cards/AdminSetting/ThemeSetting';
import { PaymentMethod } from '../../components/cards/AdminSetting/PaymentCard';

export const Settings = () => {
    // State to track the currently selected card
    const [activeCard, setActiveCard] = useState('theme'); // Default to 'theme'

    // Function to change the active card
    const handleLeftCardClick = (card) => {
        setActiveCard(card);
    };

    return (
        <>

            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Settings</p>
                </div>
                <div className="app-container">

                    <div className="d-flex justify-content-between setting-section match-height">
                        <div className="card left-side">
                            <LeftCard
                                one="Theme Settings"
                                two="Payment Method"
                                three="Profile Settings"
                                // four="Location/Space Settings"
                                onCardClick={handleLeftCardClick} // Pass the handler
                            />
                        </div>
                        <div className="card right-side">
                            {activeCard === 'theme' && <ThemeSetting />}
                            {activeCard === 'payment' && <PaymentMethod />}
                            {activeCard === 'profile' && <ProfileCard />}
                            {/* {activeCard === 'location' && <p>Location/Space Settings Content</p>} */}
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};
