

import { OverviewCard } from './Card';

export const SpaceOverview = (props) => {
    return (
        <>
            <div class="card-body pt-6">
                <div class="details-content p-0 position-relative">
                    <h5 class="mb-6 text-dark">Overview</h5>
                    <div class="d-flex mt-5 p-0 gap-2">
                        <OverviewCard />
                        <OverviewCard />
                        <OverviewCard />
                    </div>
                    <div class="d-flex mt-5 flex-column title gap-0 mb-0">
                        <h5 class="mb-1 text-dark">About Space</h5>
                        <p class="mb-0 link-s">Description: Lorem ipsum odor amet, consectetuer adipiscing elit. In aptent tellus convallis integer mattis id, lectus efficitur fames. Nunc curae sagittis blandit purus a pulvinar.</p>
                    </div>
                    <h5 class="mb-1 mt-5 text-dark">Amenities</h5>
                    <div class="d-flex mt-5 gap-1 animation">
                        <OverviewCard />
                        <OverviewCard />
                    </div>
                </div>
            </div>
        </>
    )
}