import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const subscriptionData = [
    { month: 'Jan', thisMonth: 100, lastMonth: 90, allTime: 100 },
    { month: 'Feb', thisMonth: 120, lastMonth: 100, allTime: 50 },
    { month: 'Mar', thisMonth: 200, lastMonth: 110, allTime: 100 },
    { month: 'Apr', thisMonth: 170, lastMonth: 200, allTime: 280 },
    { month: 'May', thisMonth: 450, lastMonth: 140, allTime: 130 },
    { month: 'Jun', thisMonth: 200, lastMonth: 160, allTime: 200 },
    { month: 'Jul', thisMonth: 260, lastMonth: 200, allTime: 340 },
    { month: 'Aug', thisMonth: 230, lastMonth: 300, allTime: 200 },
    { month: 'Sep', thisMonth: 240, lastMonth: 200, allTime: 380 },
    { month: 'Oct', thisMonth: 400, lastMonth: 210, allTime: 200 },
    { month: 'Nov', thisMonth: 260, lastMonth: 220, allTime: 350 },
    { month: 'Dec', thisMonth: 210, lastMonth: 230, allTime: 150 },
];

const SubscriptionChart = () => {
    return (
        <div className="card">
            <div className="card-body">
                {/* Chart Header */}
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <p className="bold mb-1">Subscriptions Over Time</p>
                        <p className='mb-4'>Commercial networks & enterprises</p>
                    </div>
                    {/* Three Headings with Corresponding Numbers */}
                    <div className="d-flex">
                        <div className="text-start mx-2">
                            <p className="mb-0">

                                <strong>3,524</strong>
                            </p>
                            <p className="mb-0">
                                <span style={{ backgroundColor: '#8884d8', borderRadius: '50%', display: 'inline-block', width: '10px', height: '10px', marginRight: '5px' }}></span>This Month</p>
                        </div>
                        <div className="text-start mx-2">
                            <p className="mb-0">

                                <strong>

                                    $4,467</strong>
                            </p>
                            <p className="mb-0">
                                <span style={{ backgroundColor: '#82ca9d', borderRadius: '50%', display: 'inline-block', width: '10px', height: '10px', marginRight: '5px' }}></span>Last Month</p>
                        </div>
                        <div className="text-start mx-2">
                            <p className="mb-0">
                                <strong>$22,675</strong>
                            </p>
                            <p className="mb-0">
                                <span style={{ backgroundColor: '#ff7300', borderRadius: '50%', display: 'inline-block', width: '10px', height: '10px', marginRight: '5px' }}></span>
                                All Time</p>
                        </div>
                    </div>
                </div>

                {/* Line Chart */}
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                        data={subscriptionData}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line type="monotone" dataKey="thisMonth" stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="lastMonth" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="allTime" stroke="#ff7300" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend verticalAlign="top" align="right" /> */}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default SubscriptionChart;
