import { PaymentCard } from '../../components/cards/PaymentCard';
import { PackageOffcanvas } from '../../components/Offcanvas/admin/PlackageOffcanvas';

export const Subscriptions = () => {
    return (
        <>
            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Subscriptions</p>
                    <a
                        className="btn btn-primary btn-sm "
                        // href="javascript:void(0)"
                        tabindex="-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                    >
                        Add Plan
                    </a>
                </div>
                <div className="app-container">
                    <div className=" container-fluid">
                        <div className="row match-height">
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                            <div class="col-sm-3 mt-3">
                                <PaymentCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PackageOffcanvas />
        </>
    )
}