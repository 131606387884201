import img from "../assets/images/logo.png";
const ConfirmEmail = () => {
    return(
        <div className="auth-page w-100 bg-confirmEmail d-flex flex-column justify-content-center align-items-center">
        <div className="content">
            <div className="logo text-center w-100">
                <img src={img} alt="background-image"/>
            </div>
            <p className="link pt-6 pb-7 text-white text-center mx-auto mb-0">Thank you for joining Flex Space</p>
            <div className="transparaent-card  pt-9 px-6 pb-8 rounded-4 w-100">
                <div className="px-6">
                    <div className="pb-7 text-center">
                        <svg className="stroke-primary" width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="2" width="146" height="146" rx="73" stroke-width="4"/>
                            <path d="M63.558 60.5349C65.9603 60.5349 65.931 58.7185 65.14 57.8103C64.349 56.9021 60.7748 52.3318 54.0365 52.3318C47.3568 52.3318 43.8705 56.7263 42.9623 57.781C42.9623 57.781 43.724 56.9021 42.933 57.8103C42.1419 58.7185 42.1712 60.5349 44.515 60.5349C46.8587 60.5349 48.558 58.2791 54.0365 58.2791C59.6908 58.2498 61.1556 60.5349 63.558 60.5349ZM98.6849 44.9783C93.7923 44.9783 89.8373 49.8708 89.8373 55.8767C89.8373 61.8826 93.8216 66.7751 98.6849 66.7751C103.577 66.7751 107.533 61.8826 107.533 55.8767C107.533 49.8708 103.577 44.9783 98.6849 44.9783ZM86.6732 95.574C75.2474 98.7087 63.0599 95.574 54.5931 87.3123C52.9818 85.7009 50.3451 85.7009 48.7337 87.283C47.0931 88.8943 47.0931 91.5896 48.7923 93.2009C59.3685 103.513 74.6615 107.469 88.8998 103.513L88.9876 103.484C94.2611 101.99 91.9759 94.0505 86.6732 95.574Z"/>
                        </svg>
                        <p className="mb-0 mt-7 light-heading px-4 mx-auto text-black">Your domain is created please check your email for details!</p>
                    </div>
                    <div className="px-6 my-6">
                        <button className="btn btn-primary btn-lg link w-100">Proceed</button>
                    </div>
                    <div className="text-end pt-7">
                        <a href="#" className="text-decoration-none link text-black">Privacy policy</a>
                    </div>
                </div>
            </div>
        </div>
       </div>
    )
}
export default ConfirmEmail;