import { EmailInbox } from "../../components/Email";
import { PageHeader } from "../../components/components";
export const ProviderEmails = () => {
  return (
    <>
      <div className="main-content app-content">
        <PageHeader pageNmae="Inbox" />
        <div className="app-container">
          <EmailInbox />
        </div>
      </div>
      {/* <Offcanvas /> */}
    </>
  );
};
