import { ReservationCard } from '../../components/cards/provider/ReservationCard';

export const Reservation = () => {
    return (
        <>

            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Reservations</p>
                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">Book Space</a>
                </div>
                <div className="app-container">
                    <div className=" container-fluid">
                        <div className="row match-height">
                            <div class="col-12 mt-3">
                                <ReservationCard />
                            </div>
                            <div class="col-12 mt-3">
                                <ReservationCard />
                            </div>
                            <div class="col-12 mt-3">
                                <ReservationCard />
                            </div>
                            <div class="col-12 mt-3">
                                <ReservationCard />
                            </div>
                            <div class="col-12 mt-3">
                                <ReservationCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Offcanvas /> */}

        </>
    )
}