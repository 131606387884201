
import { Card } from '../../components/cards/Card';
import { Table6 } from '../../components/tables/Table6';
import { CardHover } from '../../components/cards/CardHover';
import { Search } from '../../components/forms/Search';

const PaymentHistory = () => {
    const Icon1 = (
        <span class="badge-lg badge badge-circle circle-color">
            <svg class="icon" width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_188_13668)">
                    <path d="M15.5203 5.36094H16.0969V6.44844H15.5203V5.36094ZM15.5203 9.76251H16.6875V10.8641H15.5203V9.76251ZM15.5203 11.9281H16.6875V13.0578H15.5203V11.9281ZM15.5203 14.1734H16.6875V15.275H15.5203V14.1734ZM15.5203 16.3344H16.6875V17.4641H15.5203V16.3344ZM15.5203 18.5141H16.6875V19.6297H15.5203V18.5141ZM3.74063 6.65938H13.3922V7.65313H3.74063V6.65938ZM3.74063 9.33594H13.3922V10.3297H3.74063V9.33594ZM18.7313 12.0359H23.3344V13.0297H18.7313V12.0359ZM18.7313 14.5297H23.3344V15.5234H18.7313V14.5297ZM18.7313 17.2016H23.3344V18.1953H18.7313V17.2016ZM20.8969 3.11563L15.4922 8.76407H21.4969V4.60626H24.9V20.4313H2.1V4.60626H16.0922V3.11563H0.609375V21.9219H26.3906V3.11563H20.8969ZM20.0016 7.27344L18.9422 7.31094L20.0016 6.25157V7.27344ZM11.1797 16.2781C11.0906 16.0391 10.9312 15.8094 10.7062 15.5844C10.4766 15.3594 10.1719 15.1672 9.7875 14.9891L9.10781 14.6844V13.0156C9.49687 13.0578 9.71719 13.3438 9.77344 13.8688H11.0063C11.0063 13.3438 10.8328 12.9125 10.4906 12.5891C10.1484 12.2609 9.68437 12.0734 9.10781 12.0266V11.7219H8.40469V12.0266C7.86563 12.0688 7.42969 12.2563 7.09687 12.5797C6.76406 12.9078 6.6 13.3016 6.6 13.7609C6.6 14.1406 6.72187 14.4922 6.97031 14.8109C7.21875 15.1297 7.69219 15.4531 8.40469 15.7813V17.9047C8.18906 17.8484 8.02031 17.7313 7.89844 17.5578C7.78125 17.3797 7.72031 17.1781 7.72031 16.9438C7.72031 16.9297 7.725 16.9016 7.725 16.8641H6.4875C6.53906 17.5719 6.74063 18.0781 7.09219 18.3734C7.44375 18.6688 7.87969 18.8563 8.40937 18.9266V19.3109H9.1125V18.9266C9.78281 18.9125 10.3266 18.7203 10.7203 18.3547C11.1187 17.9891 11.3203 17.5391 11.3203 17.0047C11.3156 16.7609 11.2688 16.5172 11.1797 16.2781ZM8.4 14.3656C8.02031 14.1406 7.83281 13.8922 7.83281 13.6156C7.83281 13.2922 8.02031 13.0953 8.4 13.025V14.3656ZM9.81562 17.7125C9.63281 17.8719 9.39375 17.9563 9.10781 17.9516V16.0906C9.76406 16.3438 10.0922 16.6906 10.0922 17.1266C10.0922 17.3516 9.99844 17.5484 9.81562 17.7125Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_188_13668">
                        <rect width="25.7812" height="24" fill="white" transform="translate(0.609375 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    )
    const Icon2 = (
        <span class="badge-lg badge badge-circle circle-color">
            <svg class="icon" width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_191_9108)">
                    <path d="M11.1324 17.0281C11.1371 16.6297 10.9777 16.2594 10.6543 15.9406C10.3262 15.6078 9.96055 15.4531 9.5668 15.4625C9.20586 15.4766 8.74648 15.5984 8.20742 15.8375C7.88398 15.9781 7.71055 16.0062 7.62148 15.9969C7.5043 15.9922 7.39648 15.9406 7.29336 15.8375C7.18555 15.7297 7.11992 15.6125 7.11523 15.4953C7.11055 15.3875 7.15273 15.275 7.26992 15.1672C7.37773 15.0594 7.49492 15.0172 7.63086 15.0406C7.78555 15.0594 7.9543 15.1625 8.11836 15.3359L8.22617 15.4437L9.1168 14.5484L9.08398 14.3984L9.05117 14.3656C8.7043 14.0094 8.32461 13.8125 7.90742 13.775C7.5418 13.7422 7.18086 13.8312 6.83867 14.0562L6.28086 13.4844L5.66211 14.1078L6.21523 14.6703C5.97617 15.0031 5.8543 15.3547 5.8543 15.7156C5.8543 16.1234 6.00898 16.4844 6.32305 16.7984C6.65586 17.1312 7.02617 17.3 7.42461 17.2812C7.79023 17.2672 8.24492 17.1312 8.77461 16.8875C9.01367 16.7797 9.20586 16.7328 9.34648 16.7375C9.46836 16.7422 9.58555 16.8031 9.69336 16.9109C9.80117 17.0187 9.85273 17.1359 9.85273 17.2578C9.85273 17.3797 9.79648 17.5015 9.66523 17.6234C9.5293 17.7594 9.38867 17.825 9.2293 17.8297C9.07461 17.8344 8.89648 17.7406 8.69961 17.5484L8.5918 17.4406L7.6918 18.35L7.74805 18.4906L7.7668 18.5094C8.16055 18.9219 8.58711 19.1234 9.02773 19.1C9.41211 19.0859 9.78242 18.95 10.1293 18.7015L10.6402 19.2172L11.259 18.5937L10.7434 18.0781C11.0012 17.7312 11.123 17.3844 11.1324 17.0281ZM13.8605 8.58124C15.173 7.62967 16.4387 6.6453 17.5684 5.60468L18.6559 6.69218C17.6434 7.64374 16.3309 8.55311 14.8309 9.55155L13.8605 8.58124ZM17.9715 12.6875L17.0012 11.7172C18.3043 10.7703 19.5793 9.78592 20.7043 8.7453L21.7965 9.82811C20.784 10.7797 19.4715 11.6891 17.9715 12.6875Z" fill="white" />
                    <path d="M25.9693 8.29999C23.6958 6.12031 20.6818 2.79687 18.563 0.762495C18.1505 0.368745 17.4943 0.424995 17.1568 0.88437C13.0552 6.49062 3.87709 10.1141 0.85834 15.3453C0.647403 15.7109 0.694278 16.1703 0.966153 16.4891C3.00053 18.8328 6.43178 22.2922 8.35834 24.2234C8.75678 24.6219 9.39897 24.5844 9.75053 24.1484C15.0943 17.4969 24.1318 14.4734 26.199 9.38281C26.3537 9.00781 26.2599 8.57656 25.9693 8.29999ZM8.98647 22.7234C7.40209 21.0641 4.48647 18.1625 2.34897 15.7859C6.02865 10.4328 12.1037 8.97968 17.9771 2.28593C20.2412 4.5125 23.6396 8.24374 24.6755 9.13437C22.7724 13.1422 14.6677 16.3484 8.98647 22.7234Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_191_9108">
                        <rect width="25.5469" height="24" fill="white" transform="translate(0.726562 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </span>

    )
    return (
        <>

            <div className="main-content app-content">
                <div className="page-header pb-0">
                    <p className="bold">Payment History</p>
                </div>
                <div className="app-container">
                    <div className="main-container container-fluid">
                        <div className="row">
                            <div className="col-sm-4 mt-3">
                                <Card description="Number of Subscriptions" number="1510" icon={Icon1} />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <Card description="Last month Earnings" number="$4373.00" icon={Icon2} />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <CardHover description="Total Earnings" number="$975474.00" icon={Icon2} />
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p className='bold '>
                                Search
                            </p>
                            <Search />
                        </div>

                        <div className="row match-height">
                            <div className="mx-0 col-xl-12 col-lg-12">
                                <Table6 id="1" requested="Doron" name="+32456754" date="$00.00" pay="7/24.2025" paystatus="Received"
                                    request="Name" space="PH" time="Received Payment" payment="Date" status="Status" actions="Actions" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Offcanvas /> */}

        </>
    )
}

export default PaymentHistory;