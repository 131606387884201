import { Table8 } from '../../components/tables/Table8';
export const Providers = () => {
    return (
        <>
            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Provider</p>
                    <a href="javascript:void(0);" className="btn btn-primary btn-sm ">Add Provider</a>
                </div>
                <div className="app-container">
                    <div className="main-container container-fluid">

                        <div className="col-xl-12 col-lg-12">
                            <Table8 heading="Bookings" id="1" requested="Ahmad" name="example@gmail.com" date="7/24/2025" pay="7/24/2025" paystatus="Active" type="Business" number="+1 123 345 6799"
                                request="Name" space="Email" time="Phone Number" payment="Subscription Type" status="Expiry Date" phone="Status" email="Date Of Birth" actions="Actions" />
                        </div>

                    </div>
                </div>
            </div>
            {/* <Offcanvas /> */}
        </>
    )
}
