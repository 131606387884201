import { PageHeader } from '../../components/components';
import { EmailInbox } from '../../components/Email';
export const AdminEmails = () => {
    return (
        <>
            <div className="main-content app-content">
                <PageHeader pageNmae="Inbox" />
                <div className="app-container">
                    <EmailInbox />
                </div>
            </div>
            {/* <Offcanvas /> */}
        </>
    )
}
