import React, { useState } from 'react';
import { LeftCard } from '../../components/cards/provider/ProviderSetting/LeftCard';
import { ProfileCard } from '../../components/cards/provider/ProviderSetting/ProfileCard';
import { PaymentMethod } from '../../components/cards/provider/ProviderSetting/PaymentCard';
import { SubscriptionCard } from '../../components/cards/provider/ProviderSetting/Subscription';
import { Certification } from '../../components/cards/provider/ProviderSetting/Certification';
export const ProviderSettings = () => {
    const [activeCard, setActiveCard] = useState('payment');

    const handleLeftCardClick = (card) => {
        setActiveCard(card);
    };
    return (
        <>

            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Settings</p>
                </div>
                <div className="app-container">

                    <div className="d-flex justify-content-between setting-section match-height">
                        <div className="card left-side">
                            <LeftCard
                                one="Payment Method"
                                two="Profile Setting"
                                three="Subscriptions"
                                four="Certification"
                                onCardClick={handleLeftCardClick}
                            />
                        </div>
                        <div className="card right-side">
                            {activeCard === 'payment' && <PaymentMethod />}
                            {activeCard === 'profile' && <ProfileCard />}
                            {activeCard === 'subscription' && <SubscriptionCard />}
                            {activeCard === 'certification' && <Certification />}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
