import React, { useRef, useState } from "react";
import { DocumentIcon } from "../../assets/images/icons";

export const DocumentField = (props) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("Attachment");

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(`${file.name}`);
    }
  };

  return (
    <div className="file-attachment">
      <input type="file" className="invisible" ref={fileInputRef} onChange={handleFileChange} />
      <div className={`border form-control form-control-md py-0 pe-0 upload-container w-100 ${props.classes}`}>
        <div>
          <DocumentIcon iconClass={`${props.iconClass}`} />
        </div>
        <span className="w-100 text-muted">{fileName}</span>
        <button type="button" className="btn btn-primary btn-md" onClick={handleBrowseClick} > Browse </button>
      </div>
    </div>
  );
};
