import img from "../assets/images/logo.png"
const Stripe = ()=>{
    return(
        <div className="auth-page w-100 bg-stripe d-flex flex-column justify-content-center align-items-center">
        <div className="content">
            <div className="logo text-center w-100">
                <img src={img} alt="background-image"/>
            </div>
            <p className="link pt-6 pb-7 text-white text-center mx-auto mb-0">We Know your needs ! Lorem ipsum odor amet, consectetuer adipiscing elit. Viverra hendrerit proin taciti vestibulum erat.</p>
            <div className="transparaent-card d-flex flex-column gap-ten p-8 rounded-4 w-100">
                <span className="link">Thank you for choosing Flex Space!</span>
                <h4 className="mb-0 pb-4">Payment Method</h4>
                <h6 className="mb-0 border-bottom pb-4">Stripe Settings</h6>
               <div className="d-flex flex-column gap-4 pt-4 pb-7 pe-7">
                <div> 
                    <label for="cardNo" className="form-label col-form-label label-md text-dark">14 Digit Card number</label>
                    <div className="border form-control form-control-lg password-container w-100">
                        <input type="password" id="cardNo" className="p-0 border-0 placeholder-lg" value="password"/> 
                        <span className="password-icon">
                            <i className="fa-solid fa-eye-slash"></i>
                            <i className="fa-solid fa-eye"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <label for="name" className="form-label col-form-label label-md text-dark">Card holder Name</label>
                    <input type="text" className="form-control form-control-md placeholder-lg" placeholder="Example: Jon" id="name"/>
                    {/* <div id="InputMd" className="form-text">We'll never share your data.</div>  */}
                </div>
                <div className="d-flex justify-content-between gap-ten">
                    <div className="w-100">
                        <label for="cardDate" className="form-label col-form-label label-md text-dark">Expiration Date</label>
                        <input type="date" className="form-control form-control-md placeholder-lg" placeholder="Example: 12/24" id="cardDate"/>
                        {/* <div id="InputMd" className="form-text">We'll never share your data.</div> */}
                    </div>
                    <div className="w-100">
                        <label for="cvc" className="form-label col-form-label label-md text-dark">CVC</label>
                        <input type="password" className="form-control form-control-md placeholder-lg" placeholder="Enter CVC" id="cvc"/>
                        {/* <div id="InputMd" className="form-text">We'll never share your data.</div> */}
                    </div>
                </div>
               </div>
                <button className="btn btn-primary btn-lg mt-4 link">Submit</button>
            </div>
        </div>
    </div>
    )
}

export default Stripe;