import React, { useState } from 'react';
import { LeftCard } from '../../components/cards/OwnerSetting/LeftCard';
import { ProfileCard } from '../../components/cards/OwnerSetting/ProfileCard';
import { ThemeSetting } from '../../components/cards/OwnerSetting/ThemeSetting';
import { SubscriptionCard } from '../../components/cards/OwnerSetting/SubscriptionCard';
import { PaymentMethod } from '../../components/cards/OwnerSetting/PaymentCard';
import { AddonsCard } from '../../components/cards/OwnerSetting/Addons';
import { SpecialtyCard } from '../../components/cards/OwnerSetting/Specialty';
import { LocationSetting } from '../../components/cards/OwnerSetting/Location';
import { Outlet } from 'react-router-dom';
export const OwnerSettings = () => {
    const [activeCard, setActiveCard] = useState('theme'); // Default to 'theme'

    // Function to change the active card
    const handleLeftCardClick = (card) => {
        setActiveCard(card);
    };
    return (
        <>
            <div className="main-content app-content">
                <div className="page-header">
                    <p className="bold">Settings</p>
                </div>
                <div className="app-container">
                    <div className="d-flex justify-content-between setting-section match-height">
                        <div className="card left-side">
                            <LeftCard
                                one="Theme Settings"
                                two="Payment Method"
                                three="Profile Settings"
                                four="Subscriptions"
                                five="Location/Space Settings"
                                six="Specialty"
                                seven="Addons"
                                onCardClick={handleLeftCardClick} // Pass the handler
                            />
                        </div>
                        <div className="card right-side">
                            {activeCard === 'theme' && <ThemeSetting />}
                            {activeCard === 'payment' && <PaymentMethod />}
                            {activeCard === 'profile' && <ProfileCard />}
                            {activeCard === 'subscription' && <SubscriptionCard />}
                            {activeCard === 'location' && <LocationSetting />}
                            {activeCard === 'specialty' && <SpecialtyCard />}
                            {activeCard === 'addons' && <AddonsCard />}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
