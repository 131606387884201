import logo from '../../../assets/images/brand/logo.png';
import '../../../assets/js/main';

const Login = () => {
    return (
        <>
            <div>
                <div class="auth-page w-100 bg-login d-flex flex-column justify-content-center align-items-center">
                    <div class="content">
                        <div class="logo text-center">
                            <img src={logo} alt="background-image"></img>
                        </div>
                        <p class="link pt-6 pb-7 text-white text-center mx-auto mb-0">We Know your needs ! Lorem ipsum odor amet, consectetuer adipiscing elit. Viverra hendrerit proin taciti vestibulum erat.</p>
                        <div class="transparaent-card d-flex flex-column p-8 rounded-4 w-100">
                            <h4 class="mb-0">Login</h4>
                            <p class="mb-4 link">Welcome again to Flex Space</p>
                            <div>
                                <label for="email" class="form-label col-form-label label-md text-dark">Email</label>
                                <input type="email" class="form-control form-control-md placeholder-lg" placeholder="example@gmail.com" id="email"></input>
                                <div id="InputMd" class="form-text">We'll never share your data.</div>
                            </div>
                            <div>
                                <label for="password" class="form-label col-form-label label-md text-dark">Password</label>
                                <div class="border form-control form-control-lg password-container w-100">
                                    <input type="password" id="password" class="p-0 border-0 placeholder-lg" value="password" autocomplete="new-password"></input>
                                    <span class="password-icon">
                                        <i class="fa-solid fa-eye-slash"></i>
                                        <i class="fa-solid fa-eye"></i>
                                    </span>
                                </div>
                            </div>
                            <button class="btn btn-primary btn-lg mt-4 link">Login</button>
                            <div class="text-end">
                                <button class="mt-7 btn-clean link text-black">Forgot your password ?</button>
                            </div>
                        </div>

                        <div class="transparaent-card p-8 mt-7 py-7 rounded-4 w-100">
                            <div class=" d-flex justify-content-between align-items-center py-7">
                                <p class="btn link p-0 text-start">Send an invitation to join your portal !</p>
                                <button class="btn btn-secondary btn-sm text-nowrap">Invite Provider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login;