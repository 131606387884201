export const SearchFrom = () => {
    return (
        <>
            <div className="card mt-3">
                <div class="card-body">
                    <form class="validated">
                        <div class="row">
                            <div class="col-sm-3">
                                <input type="text" class="form-control"
                                    id="Input" placeholder="ID"
                                    value="ID" required=""></input>
                            </div>
                            <div class="col-sm-4">
                                <input type="text" class="form-control"
                                    id="Input" placeholder="Company / Owner Name"
                                    value="Company / Owner Name" required=""></input>
                            </div>
                            <div class="col-sm-3 text-light">
                                <input type="text" class="form-control"
                                    id="Input" placeholder="Package"
                                    value="Package" required=""></input>
                            </div>
                            <div class="col-sm-2 d-grid">
                                <button class="btn btn-primary" type="submit">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}