import { Link } from "react-router-dom";

export const ProfileOffcanvas = () => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header border-bottom">
                    <h6 id="offcanvasRightLabel">Profile Setting</h6>
                    <button
                        type="button"
                        className="btn-close small"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body px-6 border-bottom">
                    <form class="form mb-8">
                        <label class="form-label col-form-label bold" for="formControlInput1">Upload Avatar</label>
                        <div class="input-group m-0">
                            <input type="text" class="form-control" placeholder="Upload" aria-label="Upload Logo" aria-describedby="basic-addon2"></input>
                            <span class="input-group-text bg-primary text-white" id="basic-addon2">Browse</span>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label bold" for="formControlInput1">Name</label>
                            <input class="form-control" id="formControlInput1" type="email" placeholder="Flex Space" data-bs-original-title="" title=""></input>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label bold" for="formControlInput1">Perfection/Tag Line</label>
                            <input class="form-control" id="formControlInput1" type="email" placeholder="We Know your needs!" data-bs-original-title="" title=""></input>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label bold" for="formControlInput1">Email address</label>
                            <input class="form-control" id="formControlInput1" type="email" placeholder="admin@flexspace.com" data-bs-original-title="" title=""></input>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label bold" for="formControlInput1">Phone Number</label>
                            <input class="form-control" id="formControlInput1" type="email" placeholder="+1 123-456-7890" data-bs-original-title="" title=""></input>
                        </div>
                        <div className="position-relative">
                            <label className="col-sm-6 form-label col-form-label bold" for="formControlInput1">Password</label>
                            <Link className="col-sm-6 ms-8" for="formControlInput1">Reset Password</Link>
                            <input
                                className="form-control"
                                id="apiKeyInput"
                                type="password"
                                placeholder="* * * * * * * * * * * *"
                                title="">
                            </input>
                            {/* Eye icon */}
                            <i className="fa fa-eye position-absolute"
                                style={{ top: '72%', right: '20px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                onClick={() => togglePasswordVisibility('apiKeyInput')}>
                            </i>
                        </div>
                    </form>
                </div>
                <div class="card-body px-2 mt-2 mb-1">
                    <div class="d-grid">
                        <button type="button" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>

        </>
    )
}

// Function to toggle password visibility
function togglePasswordVisibility(inputId) {
    const input = document.getElementById(inputId);
    if (input.type === "password") {
        input.type = "text";
    } else {
        input.type = "password";
    }
}