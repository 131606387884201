export const Footer = () => {
    return (
        <>
            <footer class="footer border-top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 px-0">
                            <p class="mb-0 light-basic text-light-base">©2024 Powered by FlexSpace</p>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <p class="mb-0 light-basic text-light-base d-flex justify-content-end align-items-center">
                                <span className="px-3">License</span>
                                <span>Contact us</span>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}