
export const OverviewCard = (props) => {
    return (
        <>
            <div class="card col-sm-4">
                <div class="d-flex mt-2 mb-2 animation ">
                    <p class="link-s mb-0 px-7 border-end d-flex align-items-center" data-bs-toggle="tooltip" data-placement="top" title="Total space size">
                        <svg class="icon" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_8_13581)">
                                <path d="M19.75 6.25625C19.75 6.58711 19.4793 6.85781 19.1484 6.85781C18.8176 6.85781 18.5469 6.58711 18.5469 6.25625L18.5297 3.4375L11.8824 10.0934C11.6504 10.3254 11.2637 10.3254 11.0316 10.0934C10.7996 9.86133 10.7996 9.47891 11.0316 9.24258L17.6789 2.59102L14.8602 2.57812C14.5293 2.57812 14.2586 2.30742 14.2586 1.97656C14.2586 1.6457 14.5293 1.375 14.8602 1.375L19.1828 1.38789C19.4922 1.38789 19.7371 1.63711 19.7414 1.94648L19.75 6.25625ZM0.5 15.7437C0.5 15.4129 0.770703 15.1422 1.10156 15.1422C1.43242 15.1422 1.70312 15.4129 1.70312 15.7437L1.72031 18.5582L8.36758 11.898C8.59961 11.666 8.98633 11.666 9.21836 11.898C9.45039 12.1301 9.45039 12.5125 9.21836 12.7488L2.57109 19.409L5.38984 19.4219C5.7207 19.4219 5.99141 19.6926 5.99141 20.0234C5.99141 20.3543 5.7207 20.625 5.38984 20.625L1.06719 20.6121C0.757812 20.6121 0.512891 20.3629 0.508594 20.0535L0.5 15.7437ZM19.7371 20.6078L14.8602 20.625C14.5293 20.625 14.2586 20.3543 14.2586 20.0234C14.2586 19.6926 14.5293 19.4219 14.8602 19.4219L17.6832 19.4047L11.0145 12.7703C10.7824 12.5383 10.7824 12.1559 11.0145 11.9195C11.2465 11.6832 11.6332 11.6875 11.8652 11.9195L18.534 18.5539L18.5469 15.7437C18.5469 15.4129 18.8176 15.1422 19.1484 15.1422C19.4793 15.1422 19.75 15.4129 19.75 15.7437L19.7371 20.6078ZM0.512891 1.94219C0.512891 1.63281 0.762109 1.38789 1.07148 1.38359L5.38984 1.375C5.7207 1.375 5.99141 1.6457 5.99141 1.97656C5.99141 2.30742 5.7207 2.57812 5.38984 2.57812L2.5668 2.59531L9.24414 9.22539C9.47617 9.45742 9.47617 9.84414 9.24414 10.0762C9.01211 10.3082 8.62539 10.3082 8.39336 10.0762L1.71602 3.4418L1.70312 6.25195C1.70312 6.58281 1.43242 6.85352 1.10156 6.85352C0.770703 6.85352 0.5 6.58281 0.5 6.25195L0.512891 1.94219Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_8_13581">
                                    <rect width="19.25" height="22" fill="white" transform="translate(0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </p>
                    <div className='px-5 align-items-center'>
                        <p className='mb-0 bold'>size (sq/ft)</p>
                        <p className='mb-0'>300</p>
                    </div>
                </div>
            </div>

        </>
    )
}